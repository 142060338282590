import React, { useEffect, useRef, useState } from 'react';
import '../email_form/style.css';
import emailjs from '@emailjs/browser';
import { Button, Form, FormGroup, FormControl, FormLabel, Modal, Imagem} from 'react-bootstrap';
import ClipLoader from 'react-spinners/BarLoader';
import { BarLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { motion } from "framer-motion";

// class Email_Form extends React.Component { // lawas
const ContactForm = () => {
  const formRef = useRef(null);  // useRef untuk referensi form
  const [showToast, setShowToast] = useState(false); // Kontrol Toast
  const [message, setMessage] = useState(""); // Untuk pesan notifikasi
  const [loading, setLoading] = useState(false);
  const [sendTime, setSendTime] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState('');

  const feedbackOptions = [
    { emoji: '😍', text: 'Sangat Puas' },
    { emoji: '👍', text: 'Pasti Akan Merekomendasikan' },
    { emoji: '⭐', text: 'Kualitas Luar Biasa' },
    { emoji: '🛠️', text: 'Sangat Mudah Digunakan' },
    { emoji: '🎨', text: 'Desain Sangat Menarik' },
  ];

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
  };

  useEffect(() => {
    // Mengatur waktu pengiriman
    const date = new Date();
    setSendTime(date.toLocaleString());

    // Mendapatkan IP Address dari layanan eksternal
    const fetchIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIp();
  }, []);

  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1; // karena bulan dimulai dari 0
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Format tanggal dengan cara manual
  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

  const sendEmail = (e) => {
    e.preventDefault();
    
    // emailjs.sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", formRef.current, "YOUR_PUBLIC_KEY")
    emailjs.sendForm(
      "service_8hln8hd", "template_2ci1qgk", formRef.current, "-spt-8uoi36P6kRB6")
      .then((result) => {
        setLoading(false);
        setMessage("Pesan berhasil dikirim! Terima kasih telah menghubungi kami.");
        setShowToast(true); // Menampilkan notifikasi sukses
        formRef.current.reset(); // Reset form setelah berhasil
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Oops! Terjadi kesalahan, coba lagi nanti.");
        setShowToast(true); // Menampilkan notifikasi error
      });
  };

  const showCustomToast = () => {
    toast(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Tampilkan toast setiap kali showToast berubah
  React.useEffect(() => {
    if (showToast) {
      showCustomToast();
      setShowToast(false); // Reset showToast setelah tampil
    }
  }, [showToast]);
  // const handleToastClose = () => setShowToast(false); // Menyembunyikan toast

  const text = "Say Hello";
  return (
    <>
      <Form ref={formRef} onSubmit={sendEmail} className="Contact-form">
        <input type="hidden" name="send_time" value={sendTime} />
        <input type="hidden" name="ip_address" value={ipAddress} />
        <h1 className="contact-form-header">Kontak Kami</h1>
        <div className="text-center"><h4>
          {text.split("").map((letter, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 1 }}
              animate={{ opacity: 0 }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: index * 0.1,
              }}
            >
              {letter}
            </motion.span>
          ))} 
          😊
          </h4>
        </div>
        <Form.Group className="contact_input mb-3">
          <Form.Control type="email" name="email" className="contact_input_text" placeholder="Email Address" required/>
        </Form.Group>

        <Form.Group controlId="formBasicName" className="contact_input mb-3">
          <Form.Control type="text" name="from_name" rows="1" placeholder="Your Name" className="contact_input_text" required />
        </Form.Group>

        <Form.Group controlId="formBasicSubject" className="contact_input mb-3">
          <Form.Control type="text" name="subject"  rows="1" placeholder="Subject" className="contact_input_text" required />
        </Form.Group>

        <Form.Group className="contact_input text-md-start" controlId="formBasicRadio">
          <Form.Label>Feedback Anda?</Form.Label>
          {feedbackOptions.map((option, index) => (
            <div className="custom-radio-group ">
            <Form.Check
              key={index}
              type="radio"
              id={`feedback-option-${index}`}
              label={`${option.emoji} ${option.text}`}
              name="feedback"
              value={option.text}
              onChange={() => setSelectedFeedback(option.text)}
              className="mb-2" required
            />
            </div>
          ))}

          <input type="hidden" name="reason" value={selectedFeedback} />
        </Form.Group>
        <Form.Group controlId="formBasicMessage" className="contact_input mb-3">
          <Form.Control as="textarea" name="message" rows="6" placeholder="Your Message" className="contact_input_text" required/>
        </Form.Group>

        <Button type="submit" disabled={loading} className="contact-email-text-btn" variant="outline-light" size="lg">
          {loading ? (
            <BarLoader color="#ffffff" height={4} width={100} />
          ) : "Kirim"}
        </Button>
      </Form>


      {/* Toast untuk Menampilkan Pesan Sukses/Gagal */}
      <ToastContainer position="top-right" autoClose={4000} />
    </>
  );
};


//   emailjs.send("service_8hln8hd","template_2ci1qgk",{
//     from_name: "from name",
//     to_name: "to name",
//     email: "bayusutrisno@outlook.com",
//     rate: "2",
//     message: "pesan pesan",
//     time: "2024",
//     ip_address: "1.1.1.1",
//     reply_to: "reply ga nih",
//     });
//   render() {
//     return (
  
//           <Form onSubmit={this.handleSubmit.bind(this)} className="Contact-form">
//             <p className="contact-form-header">Contact Form</p>
//              <Form.Group className="contact_input mb-3">
//               <Form.Control type="email" value={this.state.email} className="contact_input_text" onChange={this.handleChange.bind(this, 'email')} placeholder="Email Address" required/>
//              </Form.Group>
//             <Form.Group controlId="formBasicName" className="contact_input mb-3">
//               <Form.Control as="textarea" rows="1" value={this.state.name} onChange={this.handleChange.bind(this, 'name')} placeholder="Your Name" className="contact_input_text" required />
//             </Form.Group>
//             <Form.Group controlId="formBasicSubject" className="contact_input mb-3">
//              <Form.Control as="textarea" rows="1"  value={this.state.subject} onChange={this.handleChange.bind(this, 'subject')} placeholder="Subject" className="contact_input_text" required />
//             </Form.Group>

//             <Form.Group className="contact_input text-md-start" controlId="formBasicRadio">
//               <Form.Label>What are you contacting us about?</Form.Label>
//               <div className="custom-radio-group ">
//                 <Form.Check
//                   type="radio"
//                   id="option1"
//                   label="Website Development"
//                   name="contactReason"
//                   value="Website Development"
//                   checked={this.state.selectedOption === 'Website Development'}
//                   onChange={this.handleOptionChange}
//                   className="custom-radio mb-2"
//                 />
//                 <Form.Check
//                   type="radio"
//                   id="option2"
//                   label="App Development"
//                   name="contactReason"
//                   value="App Development"
//                   checked={this.state.selectedOption === 'App Development'}
//                   onChange={this.handleOptionChange}
//                   className="custom-radio mb-2"
//                 />
//                 <Form.Check
//                   type="radio"
//                   id="option3"
//                   label="Other Services"
//                   name="contactReason"
//                   value="Other Services"
//                   checked={this.state.selectedOption === 'Other Services'}
//                   onChange={this.handleOptionChange}
//                   className="custom-radio mb-2"
//                 />
//               </div>
//             </Form.Group>
//             <Form.Group controlId="formBasicMessage" className="contact_input mb-3">
//               <Form.Control as="textarea" rows="6" value={this.state.message} placeholder="Your text" onChange={this.handleChange.bind(this, 'message')} className="contact_input_text" required/>
//             </Form.Group>
//            <Button className="contact-email-text-btn" variant="outline-light" size="lg" type="submit">
//               Submit
//               <ClipLoader
//                 size={5} // or 150px
//                 color={"#ffffff"}
//                 loading={this.state.loading}
//               />
//             </Button>
            
//             <Modal
//                 size="md"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 show={this.state.show} onHide={this.handleClose}
//                 centered
//               >
//               <Modal.Body className="contact_success_modal_body">
//                 <Image className="contact_success_modal_img" src="https://icon-library.net/images/success-icon/success-icon-5.jpg" />
//                 <h5>Thank you <span><strong>{this.state.name}</strong>!!</span> 😇</h5>
//                 <h6>Your message was successfully recorded</h6>
//                 < br />
//                 <Button variant="outline-light" size="lg" onClick={this.handleClose} className="contact-email-text-btn">Close</Button>
//               </Modal.Body>
//            </Modal>
//           </Form>
//     )
//   }
// }

export default ContactForm