import React, { useState, useEffect } from "react";
// import endpoints from "../Endpoints.js";
import {Container, Row, Col } from 'react-bootstrap';
import VerticalTimelinecomp from "./VerticalTimelinecomp";
import FallbackSpinner from "../Spinner";
import experience from '../../data/experiences.json';
import TitleWithAnimation from '../TitleWithAnimation.js';
import './style.css';

const Experience = (props) => {
    const [data, setData] = useState(null);
    const { header } = props;
    const [loading, setLoading] = useState(true);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(experiences, { method: "GET" });
    //             const res = await response.json();
    //             setData(res);
    //         } catch (err) {
    //             console.error("Experience fetch Error", err);
    //         }
    //     };
    //     fetchData();
    // }, []);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            // Simulasi pengambilan data
            await new Promise(resolve => setTimeout(resolve, 2000));
            // Ambil data dari API atau JSON
            setData(experience.experiences);
            setLoading(false);
        };
        fetchData();
    }, []);
    return (
        loading  ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <FallbackSpinner />
            </div>
        ) : (
            <div id="/experience" style={{ marginTop: '0px' }}>
                <Container className="mt-5">
                    <Row>
                    <Col xl={12} className="zindex1 mt-4">
                        <h1 className="text-uppercase">
                            <TitleWithAnimation title="Pengalaman dan Karya" /> 
                        </h1>
                        <br />
                        </Col>
                    </Row>
                </Container>
                <div className="eachSection_height">
                <VerticalTimelinecomp sectionType={data} />
                </div>
            </div>
        )
    );
};

export default Experience;