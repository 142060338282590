import React from 'react';
import './style.css';
import {
 Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faGithub, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function SocialIcons() {
  return (
      <Col xs={12} md={6} xl={6} className="text-center social-icons">
        <a href="https://wa.me/+6285725617124" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="wa" icon={faWhatsapp} />
        </a>
        <a href="https://www.instagram.com/jokiprogram.solo/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="insta" icon={faInstagram} />
        </a>
        <a href="https://www.instagram.com/petrusbayu/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="insta" icon={faInstagram} />
        </a>
      </Col>
  );
}

export default SocialIcons;
