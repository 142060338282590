import React from 'react';
import '../contact_left/style.css';
import {Row, Col, Card, Button } from 'react-bootstrap';
import SocialIcons from '../social_icons';
import { FaCode, FaRegFileAlt, FaClipboardCheck } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const servicesData = [
  {
    icon: <FaCode className="service-icon" />,
    title: 'Pengembangan Aplikasi Web',
    description: 'Membantu Anda merancang dan mengembangkan aplikasi web yang responsif dan interaktif.'
  },
  {
    icon: <FaRegFileAlt className="service-icon" />,
    title: 'Desain UI/UX',
    description: 'Kami menawarkan layanan desain antarmuka pengguna yang menarik dan intuitif untuk pengalaman pengguna yang optimal.'
  },
  {
    icon: <FaClipboardCheck className="service-icon" />,
    title: 'Pengujian & Pemeliharaan',
    description: 'Jaminan kualitas melalui pengujian menyeluruh dan pemeliharaan aplikasi untuk menjaga performa terbaik.'
  }
];

const Contact_Left = () => {
  
  return (
    <section >
      <Row className="Contact-text">
        <Col xl={12}>
        <h1 className="p-heading1">
          Layanan Kami</h1>
        <div className="reposisi-card">
        <div className="services-section">
          {/* <h2 className="section-title">Layanan Kami</h2> */}
          <div className="services-list">
            {servicesData.map((service, index) => (
              <div className="service-item" key={index}>
                {service.icon}
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
        </div>

        <div className="reposisi-card">
        <div className="layanan-kami text-center">
          <h2 className="section-title">Kontak Kami</h2>
          <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
            <p className="m-0"><strong>Telepon:</strong></p>
            <a href="https://wa.me/6285725617124?text=Halo, saya membutuhkan bantuan terkait layanan." rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}>
              <Button
                type="button"
                className="contact-hubungi-kami-text-btn d-flex align-items-center"
                variant="outline-light"
                size="lg"
                style={{ padding: '0.5rem 1rem', display: 'inline-flex', gap: '0.5rem' }}
              >
                <FontAwesomeIcon icon={faWhatsapp} className="wa-icon" style={{ fontSize: '1.5rem' }} />
                <span>Hubungi Kami</span>
              </Button>
            </a>
          </div>
          <p><strong>Email:</strong> <a href="mailto:webmaster@bayoe.id" style={{ textDecoration: 'none' }}>webmaster@bayoe.id</a></p>
          <Card className="social-media-card col-md-6 mx-auto">
            <Card.Body>
            <span style={{ color: 'white' }}>Sosial Media Kami:</span>
            <div className="d-flex justify-content-around">
                <SocialIcons />
              </div>
            </Card.Body>
          </Card>

          {/* <div className="social-media-links">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="social-icon" />
            </a>
          </div> */}
        </div>
        </div>
        </Col>
      </Row>
    </section>
  );
}

export default Contact_Left;
