import React from 'react';
import './style.css';
import Typed from'./Typed.js';
import {
  Container,
  Row,
  Col,
  Button
 } from 'react-bootstrap';
// import ReactTypingEffect from 'react-typing-effect';
import {
   Link
  } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

  function Home_Left() {
  return (
   <Container className="home-left" >
    <Row className="home-left-main">
       <Col xl={12} className="home-left-main-col">
         <h1 className="first-line">
           Hello! 
         </h1>
         <h2 className="second_line">
           I am <strong>Bayoe iD</strong>
         </h2>
         <h3 className="mb-3">
         <Typed/> 
         {/* <ReactTypingEffect
            text={[
              "Mencari solusi pengembangan aplikasi?",
              "Butuh joki program terpercaya?",
              "Kami hadir untuk membantu Anda!",
              "Solusi web yang efisien, cepat, dan profesional.",
              "Hubungi kami untuk konsultasi gratis!",
            ]}
            className="typical"
            speed={80}
            eraseSpeed={50}
            eraseDelay={3000}
          /> */}
         </h3>
        </Col>          
           <br />
           <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center' }} className="my-3">
           <Row className=" text-center my-3 g-3">
            <Col xs={12} sm="auto">
            <Button variant="outline-light" size="lg" className="home-left-aboutmme-btn" ><Link to="/profil" style={{ textDecoration: 'none' }} className="home-left-aboutme">ℹ️ Profil</Link></Button>            </Col>
            <Col xs={12} sm="auto">
              <Button variant="outline-light" size="lg" className="home-left-aboutmme-btn">
                {/* <Link to="/about" style={{ textDecoration: 'none', color: 'white' }} className="home-left-aboutme"></Link> */}
                <a href="https://apps.bayoe.id" rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: 'white' }} className="home-left-aboutme">
                    <span> 💻 Applications</span>
                </a>
              </Button>
            </Col>
            <Col xs={12} sm="auto">
              <Button href="#contactMe" variant="outline-light" size="lg" className="home-left-aboutmme-btn">
                <ScrollLink to="contactMe" smooth={true} duration={500} offset={-15} style={{ textDecoration: 'none', color: 'white' }} className="home-left-aboutme">
                📧 Contact Us
                </ScrollLink>
              </Button>
            </Col>
            <Col xs={12} sm="auto">
              <Button
                type="button"
                className="home-left-aboutmme-btn"
                variant="outline-light"
                size="lg"
                style={{ padding: '0.5rem 1rem', display: 'inline-flex', gap: '0.5rem' }}
              >
                <a href="https://wa.me/6285725617124?text=Halo, saya tertarik dengan layanan Anda!" rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: 'white' }} className="home-left-aboutme">
                <FontAwesomeIcon icon={faWhatsapp} className="wa-icon" style={{ fontSize: '1.5rem' }} />
                    <span> Whatsapp</span>
                </a>
              </Button>
            </Col>            
          </Row>
          </div>  
           {/* <Button variant="outline-light" size="lg" className="home-left-aboutmme-btn"><a style={{ textDecoration: 'none', color: 'white' }} href="https://blog.motasimfoad.com/" target="_blank" className="home-left-aboutme"></a></Button> &nbsp; */}

     </Row>
   </Container>
  );
}

export default Home_Left;
