import React, { useCallback } from 'react';
import '../home/style.css';
import HomeLeft from '../../components/home_left';
import HomeRight from '../../components/home_right';
import Skills from '../../components/home_skills/index.js';
import Experience from '../../components/Experience/index.js';
import Footer from '../../components/footer';
import Contact from "../contact/index.js";

import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import Animate from 'react-smooth';
import { loadFull } from "tsparticles";
import { loadSlim } from 'tsparticles-slim'; 
import Particles from "react-tsparticles";
// import Particles from 'react-particles-js';
import { motion } from 'framer-motion';

import particlesJs from '../../const/particle.js';
// import ReactGa from 'react-ga';
import {Helmet} from 'react-helmet';
import NavBar from "../../assets/navBar";
//--Motasim Foads Portfolio website Google analytics--
//--Replace with your own key !!
// function Analytics(){
//   ReactGa.initialize("UA-154721739-1")
//   ReactGa.pageview('motasimfoad.com - Home Screen')
// }
//--Motasim Foads Portfolio website Google analytics--

function  Home() {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);  // Menggunakan versi slim dari tsparticles
  }, []);

  const particlesOptions = {
    background: {
      color: {
        value: "#0d47a1",  // Latar belakang biru
      },
    },
    particles: {
      number: {
        value: 50,  // Jumlah partikel
      },
      size: {
        value: 3,  // Ukuran partikel
      },
      move: {
        enable: true,  // Partikel bergerak
        speed: 2,
      },
      opacity: {
        value: 0.5,  // Transparansi partikel
      },
    },
  };
  

  return (
    <div className="page-content">
      <NavBar active="home" />
        <Helmet>  
        <meta charSet="utf-8" />
          <title>Jasa Pembuatan Aplikasi Berbasis Website & Program Pengembangan Aplikasi Web | Bayoe ID</title>
          <link rel="canonical" href="https://bayoe.id/" />
          <meta name="description" content="Jasa Joki Program dan Pembuatan Aplikasi Website | Bayoe ID - Kami menawarkan layanan pengembangan aplikasi web, software, dan pemrograman yang profesional dan tepat waktu. Dari konsultasi IT, pembuatan aplikasi custom, hingga penyelesaian tugas pemrograman, kami siap membantu proyek Anda dengan solusi digital terbaik. Hubungi kami sekarang untuk solusi web dan software yang cepat, efisien, dan sesuai kebutuhan bisnis Anda" />
         </Helmet>
      <motion.div
        initial={{ opacity: 0 }}   // Animasi dimulai dari opacity 0
        animate={{ opacity: 1 }}   // Animasi ke opacity 1
        transition={{ duration: 1 }} // Durasi animasi
      >
        <Particles
          init={particlesInit}
          options={particlesJs}
          className="particle"
        />
        <Container fluid={true}>
            <Row className="App-main">
              <Col  xl={7}  className="App-left zindex1">
                <HomeLeft />
              </Col>
              <Col  xl={5} className="App-right">
                <HomeRight />
              </Col>
            </Row>
            <section className="App-skill">
              <Skills />
            </section>
            <section id="contactMe"> 
              <Contact/>
            </section>
            <Row className="App-footer">
              <Footer />
            </Row>
        </Container>
      </motion.div>
    </div>
  );
}

export default Home;
