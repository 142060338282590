import React, { useCallback } from 'react';
import '../home/style.css';
import ProfilTop from '../../components/profil_top/index.js';
import Experience from '../../components/profil_experience/index.js';
import Footer from '../../components/footer';

import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
// import Particles from "react-tsparticles";
// import Particles from 'react-particles-js';
import { motion } from 'framer-motion';
// import particlesJs from '../../const/particle.js';
import {Helmet} from 'react-helmet';
import NavBar from "../../assets/navBar";
import AmongUsParticles from "../../components/ParticleBackgroundAmongUs.js";
// import ParticleBackground from '../../components/ParticleBackground.js'; // Untuk particles.js
// import Globe from '../../components/Globe.js'; // Untuk Three.js

const Profil = () => {
  return (
    
    <div className="page-content">
      <NavBar active="profil" />
        <Helmet>  
          <title>Pofil Bayoe ID | Jasa Pembuatan Aplikasi Berbasis Website & Program Pengembangan Aplikasi Web</title>
          <link rel="canonical" href="https://bayoe.id/" />
          {/* <meta name="description" content="Jasa Joki Program dan Pembuatan Aplikasi Website | Bayoe ID - Kami menawarkan layanan pengembangan aplikasi web, software, dan pemrograman yang profesional dan tepat waktu. Dari konsultasi IT, pembuatan aplikasi custom, hingga penyelesaian tugas pemrograman, kami siap membantu proyek Anda dengan solusi digital terbaik. Hubungi kami sekarang untuk solusi web dan software yang cepat, efisien, dan sesuai kebutuhan bisnis Anda" /> */}
         </Helmet>
         {/* <ParticleBackground /> */}
        {/* <Globe /> */}
      <AmongUsParticles />
      <motion.div
        initial={{ opacity: 0 }}   // Animasi dimulai dari opacity 0
        animate={{ opacity: 1 }}   // Animasi ke opacity 1
        transition={{ duration: 1 }} // Durasi animasi
      >
        {/* <Particles
          init={particlesInit}
          options={particlesJs}
          className="particle"
        /> */}
        <Container fluid={true}>
            <Row className="App-main">
              <Col  xl={12}  className="mt-5" style={{ marginBottom: '6.5%' }}>
                <ProfilTop />
              </Col>
              <Col  xl={12}  className="">
                <Experience />
              </Col>
            </Row>
{/*             
            <Col  xl={5} className="App-right">
                <HomeRight />
              </Col> */}
            {/* <section className="App-skill">
              <Skills />
            </section>
            <section id="contactMe"> 
              <Contact/>
            </section> */}
            <Row className="App-footer">
              <Footer />
            </Row>
        </Container>
      </motion.div>
    </div>
  );
}

export default Profil;
