import React,  { useState, useEffect, useRef }from 'react';
import { motion, useInView, useScroll } from "framer-motion";

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
};
const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
};
function MotionSkills() {
    const [isVisible, setIsVisible] = useState(false);
    const skillRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
        ([entry]) => {
            // setIsVisible(entry.isIntersecting); //berulang ketika akan memasuki layar
            if (entry.isIntersecting && !isVisible) {
                setIsVisible(true);
            }
        },
        {
            threshold: 0.2,
        }
        );

        if (skillRef.current) {
        observer.observe(skillRef.current);
        }

        return () => {
        if (skillRef.current) {
            observer.unobserve(skillRef.current);
        }
        };
    }, []);
  return (
    <motion.div
        className="skills-container"
        ref={skillRef}
        variants={container}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "16px" }}
    >
        <motion.div className="rounded skill-box" variants={item}>
            Git
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            HTML
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            CSS
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            PHP
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            Laravel
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            CodeIgniter
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            React.js
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            Vite.js
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            Bootstrap
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            NVM
        </motion.div>
        <motion.div className="rounded skill-box" variants={item}>
            Node.js
        </motion.div>
    </motion.div>
);
}

export default MotionSkills;
