import React, { Component } from 'react';
import '../loading/style.css';
import { Navigate  } from 'react-router-dom';
// import ReactGA from 'react-ga';
// import {Helmet} from 'react-helmet';
import PuffLoader from "react-spinners/PuffLoader";

class Loading extends Component {

  state = {
    redirect: false
  }

  componentDidMount() {
    //  this.id = setTimeout(() => this.setState({ redirect: true }), 2000);
  //   //--Motasim Foads Portfolio website Google analytics--
  //   //--Replace with your own key !!
  //   ReactGA.initialize("UA-154721739-1");
  //   ReactGA.pageview('motasimfoad.com - Loading Screen');
  //   //--Motasim Foads Portfolio website Google analytics--
  }
    
  // componentWillUnmount() {
  //   clearTimeout(this.id)
  // }

  render() {
    return this.state.redirect
      ? <Navigate  to="/home" />
      : <div>
         {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Jasa Pembuatan Aplikasi Berbasis Website & Program Pengembangan Aplikasi Web | Bayoe ID</title>
                <link rel="canonical" href="https://bayoe.id/" />
                <meta name="description" content="Jasa Joki Program dan Pembuatan Aplikasi Website | Bayoe ID - Kami menawarkan layanan pengembangan aplikasi web, software, dan pemrograman yang profesional dan tepat waktu. Dari konsultasi IT, pembuatan aplikasi custom, hingga penyelesaian tugas pemrograman, kami siap membantu proyek Anda dengan solusi digital terbaik. Hubungi kami sekarang untuk solusi web dan software yang cepat, efisien, dan sesuai kebutuhan bisnis Anda" />
         </Helmet> */}
        <div className="Loading-header">
          <PuffLoader
            size={'40vw'}
            color={"#fff"}
            loading={true}
          />
        </div>
        </div>
  }
}

export default Loading;
