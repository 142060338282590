import React from 'react';
import './style.css';
import {Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import SocialIcons from '../social_icons';
import { FaCode, FaRegFileAlt, FaClipboardCheck } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { motion } from "framer-motion";
import TitleWithAnimation from '../TitleWithAnimation.js';
 
const Profil_Top = () => { 
    return (
        <>
        <Container className="mt-5">
        <Row>
        <Col xl={12} className="zindex1 mt-4">
            <h1 className="text-uppercase">
                <TitleWithAnimation title="Kenali Anggota Tim" /> 
            </h1>
            <br />
          </Col>
        </Row>
        </Container>
        <motion.div
            className="h-full"
            initial={{ y: "-200vh" }}
            animate={{ y: "0%" }}
            transition={{ duration: 1 }}
            >
            {/* <Container className=""> */}
            <Row className="justify-content-center align-items-center gap-3">
                <Col xs={12} sm={6} md={4} className="deneme mb-3 img-bayoe">
                <div className="badges-container-bayoe">
                    <Col xs={12} md={12} className="">
                        <Badge bg="success" className="badge-item "> <h4> Sutrisno S.Kom </h4></Badge>
                    </Col>
                    <Badge bg="secondary" className="badge-item">Founder </Badge>
                    <Badge bg="secondary" className="badge-item">Full Stack</Badge>
                    <Badge bg="secondary" className="badge-item">Designer UI/UX</Badge>
                </div>
                </Col>
                <Col xs={12} sm={6} md={4} className="deneme mb-3 img-arif">
                <div className="badges-container-arif">
                    <Col xs={12} md={12} className="">
                        <Badge bg="success" className="badge-item"><h4>Hutomo S.Pd</h4></Badge>
                    </Col>
                    <Badge bg="secondary" className="badge-item">Founder </Badge>
                    <Badge bg="secondary" className="badge-item">Designer UI/UX</Badge>
                </div>
                </Col>
                <Col xs={12} sm={6} md={4} className="deneme mb-3 img-nowo">
                <div className="badges-container-nowo"> 
                    <Col xs={12} md={12} className="">
                        <Badge bg="success" className="badge-item"><h4>Prasojo S.I.Kom </h4></Badge>
                    </Col>
                    <Badge bg="secondary" className="badge-item">Founder </Badge>
                    <Badge bg="secondary" className="badge-item">Full Stack</Badge>
                    <Badge bg="secondary" className="badge-item">Marketing</Badge>
                </div>
                </Col>
                <Col xs={12} sm={6} md={4}  className="deneme mb-3 img-lilik">
                <div className="badges-container-nowo"> 
                    <Col xs={12} md={12} className="">
                        <Badge bg="success" className="badge-item"><h4>Sulistyawan S.E </h4></Badge>
                    </Col>
                    <Badge bg="secondary" className="badge-item">Founder </Badge>
                    <Badge bg="secondary" className="badge-item">Analysts</Badge>
                </div>
                </Col>
            </Row>
            {/* <Row className="justify-content-center align-items-center gap-3" style={{ marginBottom: "10vh", marginTop: "9%" }}>
                
            </Row> */}
        </motion.div></>
    );
}

export default Profil_Top;