import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import { Link as ScrollLink, scroller } from 'react-scroll';

import "./styles/navBar.css";
const NavBar = (props) => {
	const { active } = props;

	const navigate = useNavigate();
	const location = useLocation();
  
	const goToContactMe = () => {
	  if (location.pathname === '/') {
		// Jika sudah di halaman Home, lakukan scroll langsung
		scroller.scrollTo('contactMe', {
		  smooth: true,
		  duration: 500,
		  offset: -15,
		});
	  } else {
		// Jika di halaman lain, navigasi ke Home dulu
		navigate('/');
		// Lalu scroll setelah sampai di Home
		setTimeout(() => {
		  scroller.scrollTo('contactMe', {
			smooth: true,
			duration: 500,
			offset: -15,
		  });
		}, 100); // jeda waktu agar halaman berubah lebih dulu
	  }
	};

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">
							<li
								className={
									active === "home"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/">Home</Link>
							</li>
							<li
								className={
									active === "profil"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Nav.Link as={Link} to="/profil">Profil</Nav.Link>
								{/* <Link to="/profil">Profil</Link> */}
							</li>
							<li
								className={
									active === "pengalaman"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/#">Portofolio</Link>
							</li>
							{/* <li
								className={
									active === "projects"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/projects">Projects</Link>
							</li> */}
							<li
								className={
									active === "contact"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Nav.Link
									as={ScrollLink}
									to="contactMe"
									smooth={true}
									duration={500}
									offset={-15}
									onClick={goToContactMe}
									>
									Kontak
								</Nav.Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
