import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const TitleWithAnimation = ({ title }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once it's visible
                }
            });
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    const titleVariants = {
        hidden: {
            opacity: 0,
            y: -20, // Slide from above
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6, // Durasi animasi
            },
        },
    };

    return (
        <motion.h3
            className="" // Anda bisa menambahkan kelas CSS sesuai kebutuhan
            ref={ref} // Attach the ref to the motion h3
            variants={titleVariants}
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
        >
            {title}
        </motion.h3>
    );
};

export default TitleWithAnimation;
