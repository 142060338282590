import React, { useRef, useEffect, useState, useCallback} from 'react';
import "./style.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DiReact } from "react-icons/di";  // Contoh menggunakan react-icons
// import { SiHtml5, SiCss3 } from "react-icons/si";
import { loadSlim } from 'tsparticles-slim'; 
import { motion } from 'framer-motion';
import { IconContext } from "react-icons"; // Context untuk styling icon
// import {FaReact, FaPython, FaGitAlt, FaGithub, FaNpm, FaFigma, FaBootstrap, FaNodeJs } from "react-icons/fa";
import { FaReact, FaNodeJs, FaUbuntu } from "react-icons/fa";
import { SiExpress, SiMongodb, SiGit, SiGithub, SiJavascript, SiPostman, SiFigma, SiVercel, SiNpm, SiBootstrap, SiMysql, SiLaravel, SiHtml5, SiCss3, SiVite, SiCodeigniter  } from "react-icons/si";
import { Card, Container, Row, Col } from "react-bootstrap";
const iconMap = {
  "simple-icons:laravel": <SiLaravel />,
  "simple-icons:html": <SiHtml5 />,
  "simple-icons:css": <SiCss3 />,
  "simple-icons:vite": <SiVite />,
  "simple-icons:ci": <SiCodeigniter/>,
  "simple-icons:ubuntu": <FaUbuntu />,
  "simple-icons:react": <FaReact />,
  "simple-icons:node-dot-js": <FaNodeJs />,
  "simple-icons:express": <SiExpress />,
  "simple-icons:mongodb": <SiMongodb />,
  "simple-icons:git": <SiGit />,
  "simple-icons:github": <SiGithub />,
  "simple-icons:javascript": <SiJavascript />,
  "simple-icons:postman": <SiPostman />,
  "simple-icons:figma": <SiFigma />,
  "simple-icons:vercel": <SiVercel />,
  "simple-icons:npm": <SiNpm />,
  "simple-icons:bootstrap": <SiBootstrap />,
  "simple-icons:mysql": <SiMysql  />,
};
function SoftwareSkill({ logos, index }) {
  const [isVisible, setIsVisible] = useState(false); // Untuk menyimpan status apakah elemen terlihat
  const skillRef = useRef(null); // Ref untuk elemen yang akan diamati

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting); // Update status isVisible berdasarkan apakah elemen terlihat atau tidak
      },
      { threshold: 0.1 } // Elemen dianggap terlihat jika 10% dari elemennya ada di viewport
    );

    if (skillRef.current) {
      observer.observe(skillRef.current); // Observe elemen
    }

    return () => {
      if (skillRef.current) {
        observer.unobserve(skillRef.current); // Unobserve ketika komponen di-unmount
      }
    };
  }, []);

  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // Delay 0.3 detik antar elemen
      }
    }
  };
  
  const item = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };
  return (
    <Container>
    {logos.map((logo, index) => (
      <motion.div
          key={index}
          initial={{ opacity: 0, scale: 0.5, y: 100 }} // Mulai dari transparan, lebih kecil, dan di bawah
          animate={isVisible ? { opacity: 1, scale: 1, y: 0 } : { opacity: 0, scale: 0.5, y: 100 }} // Jalankan animasi jika elemen terlihat
          transition={{ duration: 1.5, delay: index * 0.2 }} // Durasi animasi 1.5 detik dengan delay bertahap
        >
        <Card style={{ width: '100%', margin: '1rem', textAlign: 'center' }}>
          <Card.Body className="d-flex flex-column align-items-center">
          <motion.div
        ref={skillRef} // Pasang ref ke elemen
        initial={{ opacity: 0, y: 100 }} // Mulai dari posisi di bawah layar
        animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Jalankan animasi jika elemen terlihat
        transition={{ duration: 1 }} // Atur durasi animasi
        key={index} variants={item}
      >
              <IconContext.Provider value={{ style: { fontSize: "3rem", ...logo.style } }}>
                <div className="icon-wrapper">
                  {/* Render icon sesuai dengan fontAwesomeClassname */}
                  {iconMap[logo.fontAwesomeClassname]}                  
                  {/* Badge kustom yang muncul saat hover */}
                  <span className="skill-badge">{logo.skillName}</span>
                </div>
              </IconContext.Provider>
            </motion.div>
          </Card.Body>
        </Card>
    </motion.div>
    ))}
</Container>
  );
}


export default SoftwareSkill;