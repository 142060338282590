import React from 'react';
import '../contact/style.css';
import {Container, Row, Col} from 'react-bootstrap';
import Footer from '../../components/footer';
import Animate from 'react-smooth';
// import HamburgerMenu from '../../components/hamburger_menu';
import ContactLeft from '../../components/contact_left';
import EmailForm from '../../components/email_form';
// import ReactGa from 'react-ga';
import {Helmet} from 'react-helmet';
import particles from '../../const/contact_particle.js';
// import Particles from 'react-particles-js';

const Contact = () => {
  // componentDidMount() {
  //   //--Motasim Foads Portfolio website Google analytics--
  //   //--Replace with your own key !!
  //   ReactGa.initialize("UA-154721739-1");
  //   ReactGa.pageview('motasimfoad.com - Contact Screen');
  // }
   //--Motasim Foads Portfolio website Google analytics--


    return (
    <Container className="Contact-header" fluid={true}>
      <Row className="Hamburger-menu">
        {/* <HamburgerMenu /> */}
        </Row>
        <Row className="Contact-main">
          <Col  xl={6}  className="Contact-left zindex1">
          <ContactLeft />
          </Col>
          <Col  xl={6}  className="Contact-right zindex1">
            <EmailForm />
          </Col>
        </Row>
    </Container>
    )

};

export default Contact