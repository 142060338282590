import React,  { useState, useEffect, useRef }from 'react';
import { motion, useInView, useScroll } from "framer-motion";
// import { motion } from 'framer-motion';
import './style.css';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import SocialIcons from '../social_icons';
// import SpaceBoy from "../../data/LottieFiles/SpaceBoy.json";
import MotionSkills from './motion_skills.js';
import SkillSet from './icon_skillset.js';
import TitleWithAnimation from '../TitleWithAnimation.js';


  const skillsData = [
      "Pengembangan Aplikasi Web: React JS, Node.js, Laravel, CodeIgniter",
      "Pengelolaan Basis Data: MySQL, PostgreSQL",
      "Manajemen Versi: Git, GitHub, GitLab",
      "Penguasaan Teknologi Web: HTML, CSS, Vite",
      "Pengujian API: Postman",
      "Desain UI/UX: Figma",
      "Pengembangan dan Penerapan: Vercel, NPM",
  ];
  const containerVariants = {
      hidden: {
          opacity: 0,
          transition: { staggerChildren: 0.1 },
      },
      visible: {
          opacity: 1,
          transition: { staggerChildren: 0.1 },
      },
  };

  const itemVariants = {
      hidden: {
          opacity: 0,
          y: 20, // Slide from bottom
      },
      visible: {
          opacity: 1,
          y: 0,
          transition: {
              duration: 2.2,
          },
      },
  };

const Skills = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once it's visible
                }
            });
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
  }, []);
  // console.log('isSkillRefInView state saat render:', isSkillRefInView);
  return (
    <React.StrictMode>
    {/* <Container> */}
        <Row>
        <Col xl={12} className="zindex1 mt-4">
          <div><h1 className="text-uppercase">
            <TitleWithAnimation title="Keahlian dan Pengalaman" />
            </h1></div>
          <br />
          {/* p-4 p-sm-5 p-md-5 p-lg-5 p-xl-5  */}
          <div className="container gap-3">
            <div className="row justify-content-center">
              {/* Kolom kiri */}
              <div className="col-12 col-md-6 col-lg-6 skills-section">
                <div className="content-left skills-column">
                <motion.div
                    className="list-group"
                    ref={ref} // Attach the ref to the motion div
                    variants={containerVariants}
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"} // Animate based on visibility
                >
                <h2>
                  <TitleWithAnimation title="Keahlian" />
                </h2>
                <div className="list-group">
                {skillsData.map((skill, index) => (
                     <motion.div
                        className="list-group-item"
                        key={index}
                        variants={itemVariants}
                    >
                        {skill}
                    </motion.div>
                ))}
                </div>
                </motion.div>
                </div>
              </div>
              
              {/* Kolom kanan */}
              <div className="col-12 col-md-6 col-lg-6">
                <div className="content-right text-white skills-column">
                  <MotionSkills />
                </div>
              </div>
            </div>
                {/* <div className="rounded skill-box ">
                  TypeScript
                </div>z
                <div className="rounded skill-box ">
                  Next.js
                </div>
                <div className="rounded skill-box ">
                  Tailwind CSS
                </div>
                <div className="rounded skill-box ">
                  Material ui
                </div>
                <div className="rounded skill-box ">
                  SCSS
                </div> */}
            <div className="flex flex-col gap-12 justify-center ">

              <h2 className='SkillsHeading'>
                <TitleWithAnimation title="Ketrampilan" />
              </h2>
              <div className="skills">
                <Container>
                  <Row className="justify-content-center">
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Ubuntu" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="HTML" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="CSS" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Vite" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Laravel" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Codeigniter" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Node" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Express" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Mysql" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Git" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="GitHub" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="JavaScript" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Postman" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Figma" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Vercel" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="NPM" />
                    </Col>
                     <Col xs={6} sm={6} md={4} lg={2}>
                      <SkillSet skill="Bootstrap" />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Col>
        <Row className="home-right-footer" >
          <SocialIcons />
        </Row>
        </Row>
    {/* </Container> */}
    </React.StrictMode>
  );
}

export default Skills;
