import React from 'react'; 
import { CgCPlusPlus } from "react-icons/cg";
import {DiNodejs, DiJavascript1} from "react-icons/di";
import {SiExpress, SiMongodb, SiPostman, SiVercel} from "react-icons/si";
import { motion } from 'framer-motion';
// import { skills } from "../../data/portofolio.js";
import SoftwareSkill from "./SoftwareSkill.js";

// const Skills = ({skill}) => {
    // const icon = {
    //     'C++': <CgCPlusPlus/>,
    //     Postman: <SiPostman/>,
    //     React: <FaReact/>,
    //     Javascript: <DiJavascript1/>,
    //     Node : <DiNodejs/>,
    //     Express : <SiExpress/>,
    //     MongoDb : <SiMongodb/>,
    //     Git : <FaGitAlt/>,
    //     Github : <FaGithub/>,
    //     Npm : <FaNpm/>,
    //     Figma : <FaFigma/>,
    //     Bootstrap: <FaBootstrap/>,
    //     Vercel : <SiVercel/>
    // }
//   return (

    // <div title={skill} className='SkillBox'>
    //   {icon[skill]}
    // </div>
// function  SkillsSet() {
    
//   return (
//     <div>
//     {skills.data.map((skill, index) => {
//         // console.log(skill);
//     <div className="skills-text-div">
//         <motion.div
//             animate={{ x: 100 }}
//             transition={{
//                 ease: "linear",
//                 duration: 2,
//                 x: { duration: 1 }
//               }}
//         >
//                 <h1 className="skills-heading">
//             {skill.title}
//             </h1>
//         </motion.div>
//         <motion.div
//             animate={{ x: 100 }}
//             transition={{
//                 ease: "linear",
//                 duration: 2,
//                 x: { duration: 1 }
//               }}
//         >
//             <SoftwareSkill logos={skill.softwareSkills} />
//         </motion.div>
//         {/* <motion.div
//             animate={{ x: 100 }}
//             transition={{
//                 ease: "linear",
//                 duration: 2,
//                 x: { duration: 1 }
//               }}
//         >
//             <div>
//             {skill.skills.map((skillSentence) => {
//                 return (
//                 <p
//                     className="subTitle skills-text"
//                 >
//                     {skillSentence}
//                 </p>
//                 );
//             })}
//             </div>
//         </motion.div> */}
//         </div>
//         }
//     )}
//     </div>
//   )
// }


  
  function SkillSet({ skill }) {
    const skills = {
      data: [
        {
          skillName: "React",
          softwareSkills: [
            {
              skillName: "ReactJS",
              fontAwesomeClassname: "simple-icons:react", // Perbaiki classname sesuai ikon React
              style: {
                color: "#61DAFB", // Warna ikon React
              },
            },
          ],
        },
        {
          skillName: "Ubuntu",
          softwareSkills: [
            {
              skillName: "Ubuntu",
              fontAwesomeClassname: "simple-icons:ubuntu", // Perbaiki classname sesuai ikon React
              style: {
                color: "#E95420",
              },
            },
          ],
        },
        {
          skillName: "HTML",
          softwareSkills: [
            {
              skillName: "HTML",
              fontAwesomeClassname: "simple-icons:html", // Perbaiki classname sesuai ikon React
              style: {
                color: "#E34F26",
              },
            },
          ],
        },
        {
          skillName: "CSS",
          softwareSkills: [
            {
              skillName: "CSS",
              fontAwesomeClassname: "simple-icons:css", // Perbaiki classname sesuai ikon React
              style: {
                color: "#1572B6",
              },
            },
          ],
        },
        {
          skillName: "Vite",
          softwareSkills: [
            {
              skillName: "Vite",
              fontAwesomeClassname: "simple-icons:vite", // Perbaiki classname sesuai ikon React
              style: {
                color: "#646CFF",
              },
            },
          ],
        },
        {
          skillName: "Laravel",
          softwareSkills: [
            {
              skillName: "Laravel",
              fontAwesomeClassname: "simple-icons:laravel", // Perbaiki classname sesuai ikon React
              style: {
                color: "#FF2D20",
              },
            },
          ],
        },
        {
          skillName: "Codeigniter",
          softwareSkills: [
            {
              skillName: "Codeigniter",
              fontAwesomeClassname: "simple-icons:ci", // Perbaiki classname sesuai ikon React
              style: {
                color: "#EE4623",
              },
            },
          ],
        },
        {
          skillName: "Node",
          softwareSkills: [
            {
              skillName: "Node.js",
              fontAwesomeClassname: "simple-icons:node-dot-js", // Perbaiki classname sesuai ikon Node.js
              style: {
                color: "#8CC84B", // Warna ikon Node.js
              },
            },
          ],
        },
        {
          skillName: "Express",
          softwareSkills: [
            {
              skillName: "Express",
              fontAwesomeClassname: "simple-icons:express", // Ikon Express
              style: {
                color: "#000000", // Warna ikon Express
              },
            },
          ],
        },
        {
          skillName: "MongoDB",
          softwareSkills: [
            {
              skillName: "MongoDB",
              fontAwesomeClassname: "simple-icons:mongodb", // Ikon MongoDB
              style: {
                color: "#47A248", // Warna ikon MongoDB
              },
            },
          ],
        },
        {
          skillName: "Git",
          softwareSkills: [
            {
              skillName: "Git",
              fontAwesomeClassname: "simple-icons:git", // Ikon Git
              style: {
                color: "#F1502F", // Warna ikon Git
              },
            },
          ],
        },
        {
          skillName: "GitHub",
          softwareSkills: [
            {
              skillName: "GitHub",
              fontAwesomeClassname: "simple-icons:github", // Ikon GitHub
              style: {
                color: "#181717", // Warna ikon GitHub
              },
            },
          ],
        },
        {
          skillName: "JavaScript",
          softwareSkills: [
            {
              skillName: "JavaScript",
              fontAwesomeClassname: "simple-icons:javascript", // Ikon JavaScript
              style: {
                color: "#F7DF1E", // Warna ikon JavaScript
              },
            },
          ],
        },
        {
          skillName: "Postman",
          softwareSkills: [
            {
              skillName: "Postman",
              fontAwesomeClassname: "simple-icons:postman", // Ikon Postman
              style: {
                color: "#FF6C37", // Warna ikon Postman
              },
            },
          ],
        },
        {
          skillName: "Figma",
          softwareSkills: [
            {
              skillName: "Figma",
              fontAwesomeClassname: "simple-icons:figma", // Ikon Figma
              style: {
                color: "#F24E1E", // Warna ikon Figma
              },
            },
          ],
        },
        {
          skillName: "Vercel",
          softwareSkills: [
            {
              skillName: "Vercel",
              fontAwesomeClassname: "simple-icons:vercel", // Ikon Vercel
              style: {
                color: "#000000", // Warna ikon Vercel
              },
            },
          ],
        },
        {
          skillName: "NPM",
          softwareSkills: [
            {
              skillName: "NPM",
              fontAwesomeClassname: "simple-icons:npm", // Ikon NPM
              style: {
                color: "#CB3837", // Warna ikon NPM
              },
            },
          ],
        },
        {
          skillName: "Bootstrap",
          softwareSkills: [
            {
              skillName: "Bootstrap",
              fontAwesomeClassname: "simple-icons:bootstrap", // Ikon Bootstrap
              style: {
                color: "#563D7C", // Warna ikon Bootstrap
              },
            },
          ],
        },
        {
          skillName: "Mysql",
          softwareSkills: [
            {
              skillName: "Mysql",
              fontAwesomeClassname: "simple-icons:mysql",
              style: {
                color: "#4479A1",
              },
            },
          ],
        }
      ],
    };
    const currentSkill = skills.data.find((s) => s.skillName === skill);

    return (
      <div>
        {/* {skills.data.map((skill, index) => ( */}
        
        {currentSkill && <SoftwareSkill logos={currentSkill.softwareSkills} />}
        {/* ))} */}
      </div>
    );
  }
  
  export default SkillSet;

// export default SkillsSet