import React , { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "../pages/home";
import Profil from "../pages/profil";
// import About from "../pages/about";
// import Contact from "../pages/contact";
import Loading from "../pages/loading";
// import Project from "../pages/projects";
// import PuffLoader from "react-spinners/PuffLoader";
// import HashLoader from "react-spinners/HashLoader";
// import Lottie from  "lottie-react";
// import nightsky from "../data/LottieFiles/night-sky.json";


export default function Nav() {
  const[Loader,SetLoading]=useState(false);

  useEffect(()=>{
    SetLoading(true)
  
    setTimeout(()=>{
    SetLoading(false)}
    ,1900)
  },[])  
  return Loader ? 
    <Loading/>
    :
    <Router>
     
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/profil" element={<Profil />} />
        {/* <Route path="/contact" component={Contact} />
        <Route path="/project" component={Project} /> */}
        <Route path="*" element={Loading} />
      </Routes>
    </Router>
   
 
}