import React from 'react';
import Lottie from  "lottie-react";
import './style.css';
// import Foad from '../../assets/img/foad35.png';
import {
  Container,
  Row,
  Col,
  Card
} from 'react-bootstrap';
import SocialIcons from '../social_icons';
import SpaceBoy from "../../data/LottieFiles/SpaceBoy.json";

function Home_Right() {
  return (
    <Container className="home-right">
      <Row className="home-right-main">
        <Col xl={12} className="home-right-main-col">
          <br />
            <Lottie 
              className="illustration" 
              animationData={SpaceBoy} 
              loop={true} 
            />
          {/* <Image src={Foad} className="home-right-main-img" alt="Image of Motasim Foad"/> */}
        </Col>
        <Row className="home-right-footer zindex1" >
        <Card className="social-media-card col-md-6 mx-auto">
          <Card.Body>
            <div className="d-flex justify-content-around">
              <SocialIcons />
            </div>
          </Card.Body>
        </Card>
        </Row>
      </Row>
    </Container>
  );
}

export default Home_Right;
