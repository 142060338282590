import React from 'react';
import './style.css';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

function Footer() {
  return (
    <Container className="footer" fluid={true}>
      <Row >
        <Col xl={12} className="zindex1">
        Copyright © 2022-{(new Date().getFullYear())} <strong>Bayoe.ID</strong>,  <small>V2.4</small>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
