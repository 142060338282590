import React from 'react';
import Typewriter from  'typewriter-effect';

const Typed = () => {
  return (
    <div className="TypeEffect">
      <Typewriter
        options={{
          strings: [
              "Mencari solusi pengembangan aplikasi?",
              "Butuh joki program terpercaya?",
              "Kami hadir untuk membantu Anda!",
              "Solusi web yang efisien, cepat, dan profesional.",
              "Hubungi kami untuk konsultasi gratis!"],
          autoStart: true,
          loop: true,
          delay: 70,
          deleteSpeed:20,
        }}
      />
    </div>
  )
}

export default Typed
